import { createNotification } from "@promaton/frontend-common";
import {
  FileType,
  ViewerObject,
  ViewerObjectMap,
  ViewerObjectUtils,
} from "@promaton/scan-viewer";
import { sentenceCase } from "change-case";
import { DefaultLoadingManager } from "three";
import { unzip } from "unzipit";

import { applyColorCoding, Convention } from "./colorCoding";

const archiveRegex = /\.(zip|xorder|cots|stoc|dworder|ocdx)/i;
const axsRegex = /\.(stoc|cots|dworder|ocdx)/i;
const folderSeparatorRegex = /[/\\]/;
const fileExtensionRegex = /\.[a-z]+$/i;

/**
 * Used to handle when launching the app by opening one or more files.
 */
export const processLaunchFiles = async (
  callback: (data: {
    objects: ViewerObjectMap;
    convention?: Convention;
  }) => void
) => {
  if ("launchQueue" in window && "LaunchParams" in window) {
    (window as any).launchQueue.setConsumer(
      async (launchParams: { files: any[] }) => {
        if (!launchParams.files.length) {
          return;
        }
        const fileHandles = launchParams.files as FileSystemFileHandle[];
        let shouldApplyColorConventions = true;

        const objects: ViewerObjectMap = {};
        await Promise.all(
          fileHandles.map(async (fileHandle) => {
            const file = await fileHandle.getFile();
            shouldApplyColorConventions = needsColorConventions(file.name);
            if (file.name.match(archiveRegex)) {
              DefaultLoadingManager.itemStart(`Unpacking ${file.name}`);
              await unzipFile(file, objects);
              DefaultLoadingManager.itemEnd(`Unpacking ${file.name}`);
            } else {
              const res = createObjectFromFile(file);
              if (!res) return;
              const [name, object] = res;
              objects[name] = object;
            }
          })
        );

        const res = await enhanceObjects(objects, shouldApplyColorConventions);
        callback(res);
      }
    );
  }
};

/**
 * Used to handle files added by drag-and-drop.
 */
export const processDroppedFiles = async (
  dataTransfer: DataTransferItemList,
  existing?: ViewerObjectMap
) => {
  const objects: ViewerObjectMap = {};
  let shouldApplyColorConventions = true;

  existing &&
    Object.entries(existing).forEach(([id, object]) => {
      objects[id] = { ...object };
    });

  const processDirectory = async (
    directory: FileSystemEntry | null,
    parent?: string
  ) => {
    return new Promise<boolean>((resolve) => {
      if (directory?.isDirectory) {
        const reader = (directory as FileSystemDirectoryEntry).createReader();

        const path = parent ? `${parent}/${directory.name}` : directory.name;

        const read: FileSystemEntriesCallback = async (entries) => {
          await Promise.all(
            entries.map((entry) => {
              if (entry.isFile) {
                return new Promise<boolean>((res) => {
                  (entry as FileSystemFileEntry).file((file) => {
                    processFile(file, path);
                    res(true);
                  });
                });
              } else {
                return processDirectory(entry, path);
              }
            })
          );

          if (entries.length === 100) {
            // Read entries only returns 100 items at most
            reader.readEntries(read);
          } else {
            resolve(true);
          }
        };

        reader.readEntries(read);
      } else {
        resolve(false);
      }
    });
  };

  const processFile = (file: File, directoryName?: string) => {
    const res = createObjectFromFile(file);
    if (!res) return;
    let name = res[0];
    const object = res[1];

    if (directoryName) {
      object.group = directoryName;
      name = `${directoryName}/${name}`;
    }

    objects[name] = object;
  };

  const directories: FileSystemEntry[] = [];
  for (const item of dataTransfer) {
    if (item.kind !== "file") continue;
    const entry = item.webkitGetAsEntry();
    if (entry?.isDirectory) {
      directories.push(entry);
    } else {
      const file = item.getAsFile()!;
      shouldApplyColorConventions = needsColorConventions(file.name);

      if (file.name.match(archiveRegex)) {
        DefaultLoadingManager.itemStart(`Unpacking ${file.name}`);
        await unzipFile(file, objects);
        DefaultLoadingManager.itemEnd(`Unpacking ${file.name}`);
      } else {
        processFile(file);
      }
    }
  }

  await Promise.all(
    directories.map((directory) => processDirectory(directory))
  );

  return enhanceObjects(objects, shouldApplyColorConventions);
};

/**
 * Used to handle files opened with the file picker.
 */
export const processPickedFiles = async (fileList: FileList) => {
  let shouldApplyColorConventions = true;
  const objects: ViewerObjectMap = {};
  for (const file of fileList) {
    shouldApplyColorConventions = needsColorConventions(file.name);
    if (file.name.match(archiveRegex)) {
      await unzipFile(file, objects);
      continue;
    }
    const res = createObjectFromFile(file);
    if (!res) continue;
    const [name, object] = res;
    objects[name] = object;
  }

  return enhanceObjects(objects, shouldApplyColorConventions);
};

export const processS3Files = (objects: { url: string; key?: string }[]) => {
  const objectsMap: ViewerObjectMap = {};
  let shouldApplyColorConventions = true;
  for (const { url, key } of objects) {
    if (!key) continue;
    const path = key.split("/");
    const name = key.split(".").slice(0, -1).join(".");
    shouldApplyColorConventions = needsColorConventions(name);
    const objectType = ViewerObjectUtils.inferTypeFromUrl(key);

    if (objectType === undefined) continue;

    let id = name.toUpperCase();
    if (objectsMap[id]) id = `${id}-2`;
    objectsMap[id] = {
      url,
      group: sentenceCase(path.slice(-2)[0] ?? ""),
      objectType: ViewerObjectUtils.inferTypeFromUrl(key),
      clipToPlanes: true,
    };
  }

  return enhanceObjects(objectsMap, shouldApplyColorConventions);
};

/**
 * Turns a file into a viewer object.
 */
export const createObjectFromFile = (
  file: File
): [string, ViewerObject] | undefined => {
  const name = file.name.replace(fileExtensionRegex, "");
  const objectType = ViewerObjectUtils.inferTypeFromUrl(file.name);
  if (objectType === undefined) return;
  const url = URL.createObjectURL(file);
  return [
    name,
    {
      url,
      objectType,
      clipToPlanes: true,
    },
  ];
};

/** Performs extra configuration on object map */
const enhanceObjects = async (
  objects: ViewerObjectMap,
  shouldApplyColorConventions = true
) => {
  const preProcessed = mergeFileSeries(objects);
  if (!shouldApplyColorConventions) {
    return { objects: preProcessed };
  }

  const convention = await applyColorCoding(preProcessed);
  return { objects: preProcessed, convention };
};

const getNameFromPath = (path: string) => {
  return path.split(folderSeparatorRegex).at(-1) ?? "";
};

/**
 * Makes sure image sequences are merged into one object with a list of urls.
 */
const mergeFileSeries = (objects: ViewerObjectMap) => {
  const images = Object.entries(objects)
    .filter(([_, object]) => object.objectType === FileType.DICOM)
    .sort((a, b) => {
      return getNameFromPath(a[0]).localeCompare(getNameFromPath(b[0]));
    });

  // Assume DICOM files represent series if count above threshold
  if (images.length < 4) return objects;

  const merged: ViewerObject = {
    ...images[0]?.[1],
    url: images.map(([_, object]) => object.url as string),
  };

  images.forEach(([key]) => {
    delete objects[key];
  });

  const firstKey = images[0]?.[0];

  if (firstKey !== undefined) {
    objects[firstKey] = merged;
  }

  return objects;
};

/**
 * Unzips a zip file and adds objects to object map.
 */
const unzipFile = async (file: File, objectMap: ViewerObjectMap) => {
  const destroyNotif = createNotification({
    color: "info",
    text: `Unpacking ${file.name}`,
  });

  if (file.name.match(axsRegex)) {
    const { viewerData, containerData } = await processAxsFileFormat(file);

    Object.assign(objectMap, viewerData);
    containerData &&
      Object.assign(objectMap, {
        metadata: {
          objectType: FileType.JSON,
          isMetadata: true,
          url: URL.createObjectURL(new Blob([JSON.stringify(containerData)])),
        },
      });
  } else {
    const content = await unzip(file);
    const objects = (
      await Promise.all(
        Object.entries(content.entries).map(
          async ([name, entry]): Promise<
            [string, ViewerObject] | undefined
          > => {
            const path = name.split(folderSeparatorRegex).filter(Boolean);
            const fileName = path.at(-1);
            const folder = path.at(-2);

            if (fileName?.startsWith(".")) return;

            const file = new File([await entry.blob()], fileName || "");
            const obj = createObjectFromFile(file);
            if (obj) {
              obj[0] = path.join("/").replace(fileExtensionRegex, "");
              obj[1].group = folder;
            }

            return obj;
          }
        )
      )
    ).filter((item) => !!item) as [string, ViewerObject][];

    objects.forEach(([key, object]) => {
      objectMap[key] = object;
    });
  }
  destroyNotif();
};

const processAxsFileFormat = async (file: File) => {
  const {
    unpackStocFor3DViewer,
    processDsContainer,
    processDwOrderContainer,
    processCdxContainer,
  } = await import("@stg-oneportal/axs-viewer-components");

  if (file.name.match(/\.stoc$/i)) {
    return await unpackStocFor3DViewer({
      blob: file,
      fileName: file.name,
    });
  }
  if (file.name.match(/\.cots$/i)) {
    return await processDsContainer(file);
  }

  if (file.name.match(/\.dworder$/i)) {
    return await processDwOrderContainer(file);
  }

  if (file.name.match(/\.ocdx$/i)) {
    return await processCdxContainer(file);
  }

  throw new Error("Unsupported file format");
};

const needsColorConventions = (fileName: string): boolean => {
  return fileName.match(axsRegex) ? false : true;
};
