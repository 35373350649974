import { applyColorCoding } from "@promaton/file-processing";
import {
  FileType,
  useObjects,
  useStaging,
  ViewerObject,
  ViewerObjectMap,
  ViewOrientation,
} from "@promaton/scan-viewer";
import { FC, memo, useEffect } from "react";
import ga4 from "react-ga4";

import { useIsMobile } from "../hooks/useIsMobile";
import { useAppState, ViewMode } from "../stores/useAppState";

const BASE_URL = "https://promaton-viewer-public.s3.eu-west-1.amazonaws.com";

export enum Example {
  CBCT = "CBCT",
  IOS = "IOS",
  ACD = "ACD",
  COMPLETION = "COMPLETION",
}

export const loadPublicCBCTExample = async (skipCBCT = false) => {
  const folder = `${BASE_URL}/cbct_example`;
  const res: ViewerObjectMap = {};

  if (!skipCBCT) {
    const scan: ViewerObject = {
      url: Array.from(new Array(450)).map(
        (_, i) =>
          `${folder}/Scan/scan-${(i + 1).toString().padStart(4, "0")}.dcm`
      ),
      group: "Scans",
      objectType: FileType.DICOM,
      excludeInOrientations: [ViewOrientation["3D"]],
    };

    res["CBCT Scan"] = scan;
  }

  for (let q = 1; q <= 4; q++) {
    for (let t = 1; t <= 7; t++) {
      res[`TOOTH_${q}${t}`] = {
        url: `${folder}/Teeth/tooth_${q}${t}.stl`,
        group: `Teeth`,
        clipToPlanes: true,
        objectType: FileType.STL,
        metalness: 0.3,
        roughness: 0.2,
      };
    }
  }

  res[`UPPER_JAW`] = {
    url: `${folder}/Jaws/upper_jaw.stl`,
    group: `Jaws`,
    clipToPlanes: true,
    objectType: FileType.STL,
    metalness: 0.0,
  };

  res[`LOWER_JAW`] = {
    url: `${folder}/Jaws/lower_jaw.stl`,
    group: `Jaws`,
    clipToPlanes: true,
    objectType: FileType.STL,
    metalness: 0.0,
  };

  res["NERVES"] = {
    url: `${folder}/Nerves/nerves.json`,
    group: `Nerves`,
    clipToPlanes: true,
    objectType: FileType.JSON,
    metalness: 0.3,
    roughness: 0.2,
  };

  await applyColorCoding(res);

  res["LOWER_JAW"].opacity = 0.2;
  res["UPPER_JAW"].opacity = 0.2;
  return res;
};

export const loadPublicOSSExample = async () => {
  const folder = `${BASE_URL}/oss_example_2`;
  const res: ViewerObjectMap = {};

  for (let t = 1; t <= 7; t++) {
    res[`tooth.4${t}`] = {
      url: `${folder}/4${t}.stl`,
      group: `Teeth`,
      clipToPlanes: true,
      metalness: 0.3,
      roughness: 0.2,
    };
  }
  for (let t = 1; t <= 7; t++) {
    res[`tooth.3${t}`] = {
      url: `${folder}/3${t}.stl`,
      group: `Teeth`,
      clipToPlanes: true,
      objectType: FileType.STL,
      metalness: 0.3,
      roughness: 0.2,
    };
  }

  res[`GINGIVA`] = {
    url: `${folder}/gingiva.stl`,
    clipToPlanes: true,
    objectType: FileType.STL,
  };
  res[`scan.lower`] = {
    url: `${folder}/scan.lower.stl`,
    clipToPlanes: true,
    objectType: FileType.STL,
    hidden: true,
  };
  res[`transition`] = {
    url: `${folder}/polyline.json`,
    clipToPlanes: true,
    objectType: FileType.JSON,
  };
  res[`result`] = {
    url: `${folder}/result.json`,
    clipToPlanes: true,
    objectType: FileType.JSON,
  };

  await applyColorCoding(res);

  return res;
};
export const loadPublicCompletionExample = async () => {
  const folder = `${BASE_URL}/completion-example`;
  const res: ViewerObjectMap = {};

  for (let t = 1; t <= 7; t++) {
    res[`tooth.4${t}`] = {
      url: `${folder}/tooth.4${t}.stl`,
      group: `Teeth`,
      clipToPlanes: true,
      metalness: 0.3,
      roughness: 0.2,
    };
  }
  for (let t = 1; t <= 7; t++) {
    res[`tooth.3${t}`] = {
      url: `${folder}/tooth.3${t}.stl`,
      group: `Teeth`,
      clipToPlanes: true,
      objectType: FileType.STL,
      metalness: 0.3,
      roughness: 0.2,
    };
  }

  res[`scan.lower`] = {
    url: `${folder}/scan.lower.stl`,
    clipToPlanes: true,
    objectType: FileType.STL,
    hidden: true,
  };

  res[`result`] = {
    url: `${folder}/result.json`,
    clipToPlanes: true,
    objectType: FileType.JSON,
  };

  await applyColorCoding(res);

  return res;
};

export const loadPublicACDExample = async () => {
  const folder = `${BASE_URL}/acd-example`;
  const res: ViewerObjectMap = {};

  for (let t = 1; t <= 8; t++) {
    res[`tooth.4${t}`] = {
      url: `${folder}/Teeth/tooth.4${t}.stl`,
      group: `Teeth`,
      clipToPlanes: true,
      metalness: 0.3,
      roughness: 0.2,
    };
  }
  for (let t = 1; t <= 8; t++) {
    res[`tooth.3${t}`] = {
      url: `${folder}/Teeth/tooth.3${t}.stl`,
      group: `Teeth`,
      clipToPlanes: true,
      objectType: FileType.STL,
      metalness: 0.3,
      roughness: 0.2,
    };
  }

  const crowns = [43, 46];
  crowns.forEach((crown) => {
    res[`crown.${crown}`] = {
      url: `${folder}/Crown/crown.${crown}.stl`,
      group: `Crowns`,
      clipToPlanes: true,
      metalness: 0.9,
      roughness: 0.1,
      objectType: FileType.STL,
    };
  });

  res[`GINGIVA`] = {
    url: `${folder}/Gingiva/Gingiva.stl`,
    clipToPlanes: true,
    objectType: FileType.STL,
  };
  res[`scan.lower`] = {
    url: `${folder}/Scan/dav_lower.stl`,
    clipToPlanes: true,
    objectType: FileType.STL,
    hidden: true,
  };
  res[`transition`] = {
    url: `${folder}/Transition/Polyline.json`,
    objectType: FileType.JSON,
    clipToPlanes: true,
  };
  res[`result`] = {
    url: `${folder}/Result/result.json`,
    objectType: FileType.JSON,
    clipToPlanes: true,
  };

  await applyColorCoding(res);

  crowns.forEach((crown) => {
    const crownObj = res[`tooth.${crown}`];
    if (!crownObj) return;
    crownObj.hidden = true;
    crownObj.group = "Original Teeth";
  });

  return res;
};

export const PublicExampleLoader: FC<{ id: string }> = memo(({ id }) => {
  const mobile = useIsMobile();
  const setExposure = useStaging((s) => s.setSliceExposure);
  const setViewMode = useAppState((s) => s.setViewMode);
  const setObjects = useObjects((s) => s.setObjects);

  useEffect(() => {
    (async () => {
      if (id.toUpperCase() === Example.CBCT) {
        setObjects(await loadPublicCBCTExample(mobile));
        setViewMode(ViewMode.SPLIT);
        setExposure({ contrast: 1.2 });
        ga4.event({
          category: "loading",
          action: "LoadExampleCbct",
        });
      } else if (id.toUpperCase() === Example.IOS) {
        setObjects(await loadPublicOSSExample());
        setViewMode(ViewMode.SINGLE);
        ga4.event({
          category: "loading",
          action: "LoadExampleOss",
        });
      } else if (id.toUpperCase() === Example.ACD) {
        setObjects(await loadPublicACDExample());
        setViewMode(ViewMode.SINGLE);
        ga4.event({
          category: "loading",
          action: "LoadExampleAcd",
        });
      } else if (id.toUpperCase() === Example.COMPLETION) {
        setObjects(await loadPublicCompletionExample());
        setViewMode(ViewMode.SINGLE);
        ga4.event({
          category: "loading",
          action: "LoadExampleCompletion",
        });
      }
    })();

    return () => {
      setObjects({});
    };
  }, [id]);

  return null;
});
