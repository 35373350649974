import { createNotification } from "@promaton/frontend-common";
import { FileType, useObjects } from "@promaton/scan-viewer";
import { invalidate } from "@react-three/fiber";
import { Cache, DataTexture } from "three";

const getDataTextureFromScene = (url: string) => {
  const cacheKey = Object.keys(Cache.files).find(
    (key) => key.includes(`texture`) && key.includes(url)
  );
  if (!cacheKey) throw new Error("No data found");
  return Cache.files[cacheKey]?.texture as DataTexture | undefined;
};

export const flipDataTexture = (
  axis: "x" | "y",
  data: Uint8ClampedArray,
  width: number,
  height: number
) => {
  const total = data.length;
  const original = new Uint8Array(total);
  original.set(data);

  const frameSize = width * height;
  original.forEach((val: number, i) => {
    const frame = Math.floor(i / frameSize);
    const frameOffset = frame * frameSize;
    const frameIndex = i - frameOffset;
    const frameRow = Math.floor(frameIndex / width);
    const frameRowPosition = frameIndex - frameRow * width;
    const flippedRow = height - frameRow - 1;
    if (axis === "y") {
      const flippedIndex = flippedRow * width + frameRowPosition;
      const flippedPosition = flippedIndex + frameOffset;
      data[flippedPosition] = val;
    } else if (axis === "x") {
      const flippedRowPosition = width - frameRowPosition - 1;
      const flippedIndex = frameRow * width + flippedRowPosition;
      const flippedPosition = flippedIndex + frameOffset;
      data[flippedPosition] = val;
    }
  });
  return data;
};

export const flipDicom = (axis: "x" | "y") => {
  try {
    const objectState = useObjects.getState();
    const dicomObj = Object.entries(objectState.objects).find(
      ([_, obj]) => obj?.objectType === FileType.DICOM
    )?.[1];

    const url = Array.isArray(dicomObj?.url) ? dicomObj?.url[0] : dicomObj?.url;
    if (!dicomObj || !url) throw new Error("No DICOM file found");
    const data = getDataTextureFromScene(url);
    if (!data) throw new Error("No data found");

    flipDataTexture(axis, data.image.data, data.image.width, data.image.height);
    if (axis === "x") {
      objectState.scanMetadata &&
        objectState.setScanMetadata({
          ...objectState.scanMetadata,
          flippedX: !objectState.scanMetadata.flippedX,
        });
    }
    if (axis === "y") {
      objectState.scanMetadata &&
        objectState.setScanMetadata({
          ...objectState.scanMetadata,
          flippedY: !objectState.scanMetadata.flippedY,
        });
    }

    data.needsUpdate = true;
    invalidate();
    createNotification({
      text: `Flipped DICOM along ${axis.toUpperCase()} axis`,
      color: "success",
    });
  } catch (error) {
    createNotification({
      text: `Failed to flip DICOM: ${(error as any as Error)?.message}`,
      color: "error",
    });
  }
};
