import {
  IconButton,
  Stack,
  StackProps,
  Tooltip,
  Typography,
} from "@mui/material";
import { Cloud, UploadFolder } from "@promaton/icons";
import { useState } from "react";

import { useIsPublicViewer } from "../hooks/useIsPublicViewer";
import { useAppState } from "../stores/useAppState";
import { S3LoadingDialog } from "./S3LoadingDialog";
export function AddBanner({
  onOpenFiles,
  hideSubtitle,
  titleText,
  ...rest
}: {
  onOpenFiles: (files: FileList) => void;
  hideSubtitle?: boolean;
  titleText?: string;
} & StackProps) {
  const isOnline = useAppState((s) => s.isOnline);
  const [s3DialogOpen, setS3DialogOpen] = useState(false);
  const isPublic = useIsPublicViewer();
  return (
    <label htmlFor="open-file-button-empty" style={{ flex: 1 }}>
      <Stack
        gap={3}
        {...rest}
        sx={{
          cursor: "pointer",
          flex: "1 1 0",
          flexDirection: "row",
          alignItems: "center",
          minWidth: 0,
          borderRadius: 2,
          opacity: 1,
          padding: 3,
          border: (theme) => `1px dashed ${theme.palette.action.selected}`,

          ["&:hover"]: {
            borderColor: (theme) => theme.palette.action.disabled,
            opacity: 0.8,
          },

          ...rest.sx,
        }}
      >
        <Stack flexDirection="row" gap={2} alignItems="center">
          <input
            style={{
              display: "none",
            }}
            id="open-file-button-empty"
            multiple
            type="file"
            onChange={(e) => {
              const files = e.target.files;

              if (files) {
                onOpenFiles(files);
              }
            }}
          />
          <UploadFolder fontSize="large" color="inherit" />
        </Stack>
        <Stack sx={{ minWidth: 0, flex: "1 1 0", overflow: "hidden" }}>
          <Typography
            variant="body1"
            color="text.primary"
            sx={{
              width: "100%",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {titleText || "Open or drop a file or folder to start"}
          </Typography>
          {!hideSubtitle && (
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{
                width: "100%",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              Supported files: DICOM, JSON, PSG, XRAY, VTK, PLY, STL, DRC, STOC,
              OCDX, COTS, DWORDER, ZIP.
            </Typography>
          )}
        </Stack>
        {!isPublic && (
          <>
            <Tooltip title="Load from S3">
              <IconButton
                disabled={!isOnline}
                onClick={() => {
                  setS3DialogOpen(true);
                }}
              >
                <Cloud />
              </IconButton>
            </Tooltip>
            <S3LoadingDialog
              open={s3DialogOpen}
              onClose={() => {
                setS3DialogOpen(false);
              }}
            />
          </>
        )}
      </Stack>
    </label>
  );
}
