import { copilotFunctions, CopilotMessage } from "../stores/useCopilot";

export type CopilotStatusMessage = {
  status: string;
  message?: string;
};

export const COPILOT_SUCCESS = {
  status: "success",
};

export const CopilotSceneContentDescription = {
  SCAN_ONLY: "unsegmented scan",
  SEGMENTED_UPPER: "upper jaw scan and tooth segmentations",
  SEGMENTED_LOWER: "lower jaw scan and tooth segmentations",
  SEGMENTED_BOTH: "scan(s) of both jaws and tooth segmentations",
} as const;

export const CopilotScanType = {
  CBCT: "cbct",
  INTRAORAL: "intraoral",
} as const;

type CopilotSuggestion = {
  label: string;
  message: string;
  prompt: string;
};

/** Suggestions show to the user as simple messages, but in stead send a more descriptive prompt. */
export const CopilotSuggestions: CopilotSuggestion[] = [
  {
    label: "Suggest treatment",
    message: "Suggest a treatment",
    prompt: `Analyse the scene contents. If the scene does not contain segmentations, suggest segmenting the scan first. If the scene contains segmentations, suggest one of the following:
- If there are preparations or abutments: suggest generating crowns for them
- If there are teeth missing in the jaw: suggest planning implants for them
I will provide further input based on your suggestion.
Write a short answer with a single suggestion in the form "Do you want me to ...?" citing the precise FDI location(s) the suggestion applies to.
`,
  },
  {
    label: "Change colors",
    message: "Change colors",
    prompt: `I want to change the colors of the objects in the scene. Suggest a few color palettes (only by name) as a bullet list. Then ask which objects to apply it to.`,
  },
  {
    label: "Inspect element",
    message: "Inspect element",
    prompt: `I want to inspect a specific object in a certain view. First ask me which object I want to see. Based on the answer, ask me which view I want to see it in (explaining the different options).`,
  },
  {
    label: "Edit prosthetic",
    message: "Edit prosthetic",
    prompt: `Use sculpt tool on a crown or bridge element in the scene. If there are multiple crowns ask which one to edit. If there are no crowns, suggest generating crowns first.`,
  },
];

export const DEFAULT_MESSAGE: CopilotMessage = {
  author: "assistant",
  content: "Hello! How can I help you?",
};

export const createCopilotContextPrompt = () => {
  const context = copilotFunctions.getObjects?.({});
  return `<CONTEXT>
  Scene contents: ${JSON.stringify(context, undefined)}
  </CONTEXT>`;
};
