import {
  Button,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  InputBase,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { Restore, Sparkle } from "@promaton/icons";
import { AnimatePresence, LayoutGroup, motion } from "framer-motion";
import { useEffect, useRef } from "react";
import ga4 from "react-ga4";
import { useForm } from "react-hook-form";

import {
  CopilotStatusMessage,
  CopilotSuggestions,
  createCopilotContextPrompt,
  DEFAULT_MESSAGE,
} from "../helpers/copilotPrompts";
import { trpc } from "../hooks/trpc";
import { copilotFunctions, useCopilot } from "../stores/useCopilot";

export const Copilot = () => {
  const { messages, addMessage, thread, setThread, restart } = useCopilot();
  const sendMessage = trpc.copilot.message.useMutation();

  const {
    register,
    handleSubmit,
    reset: resetForm,
  } = useForm<{
    message: string;
  }>();

  const scroll = useRef<HTMLDivElement>(null);

  const handlePrompt = async (prompt: string) => {
    const { response, threadId } = await sendMessage.mutateAsync({
      message: prompt,
      threadId: thread || undefined,
      context: thread ? undefined : createCopilotContextPrompt(),
    });

    setThread(threadId);

    const handleResponse = async (data: typeof response) => {
      if (!data) return;
      addMessage({
        author: data.function_call ? "function" : "assistant",
        content:
          (data.function_call ? data.function_call.name : data.content) ||
          "No response",
      });
      if (data.function_call?.name) {
        const func = copilotFunctions[data.function_call.name];
        const parameters = JSON.parse(data.function_call.arguments || "{}");
        let functionResult: any;

        if (!func) {
          throw new Error(`Function ${data.function_call.name} not found`);
        }

        try {
          functionResult = await func(parameters);
        } catch (e: any) {
          functionResult = {
            status: "error",
            message: (e as Error).message,
          } as CopilotStatusMessage;
        }
        const { response: newRes } = await sendMessage.mutateAsync({
          message: JSON.stringify(functionResult, undefined, 2),
          func: data.function_call.name,
          threadId,
        });

        await handleResponse(newRes);
      }
    };

    await handleResponse(response);
  };

  const onSubmit = handleSubmit(async (data) => {
    resetForm();
    addMessage({
      author: "user",
      content: data.message,
    });
    ga4.event({
      category: "copilot",
      action: "Copilot message sent",
    });
    handlePrompt(data.message);
  });

  useEffect(() => {
    scroll.current?.scrollTo({
      top: scroll.current.scrollHeight,
      behavior: "smooth",
    });
  }, [messages.length]);

  return (
    <Stack sx={{ flex: 1, minHeight: 0 }}>
      <Stack sx={{ flex: 1, overflowY: "auto", gap: 1, py: 2 }} ref={scroll}>
        <AnimatePresence>
          <LayoutGroup>
            {(messages.length > 0 ? messages : [DEFAULT_MESSAGE]).map(
              (message, index) => {
                const isFunction = message.author === "function";
                const isUser = message.author === "user";
                return (
                  <MessageContainer
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                    exit={{ opacity: 0, y: -10 }}
                    key={`${index}-${message.author}`}
                    sx={{
                      px: 2,
                      alignItems: isUser ? "flex-end" : "flex-start",
                    }}
                  >
                    <Stack
                      sx={{
                        p: isFunction ? 0 : 1.5,
                        flexDirection: "row",
                        gap: 1,
                        alignItems: "center",
                        borderRadius: (t) => t.spacing(1),
                        borderBottomRightRadius: (t) =>
                          t.spacing(isUser ? 0 : 1),
                        borderBottomLeftRadius: (t) =>
                          t.spacing(isUser ? 1 : 0),
                        border: (t) =>
                          isUser
                            ? `1px solid ${t.palette.primary.main}77`
                            : !isFunction
                            ? `1px solid ${t.palette.secondary.main}77`
                            : "none",
                        background: (t) =>
                          isUser
                            ? `${t.palette.primary.main}11`
                            : !isFunction
                            ? `${t.palette.secondary.main}11`
                            : "none",
                        color: (t) =>
                          isFunction
                            ? t.palette.text.secondary
                            : t.palette.text.primary,
                        maxWidth: "70%",
                      }}
                    >
                      {isFunction && (
                        <PulsingSpark
                          color="secondary"
                          isDone={index < messages.length - 1}
                        />
                      )}
                      <Typography
                        variant="body2"
                        sx={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}
                      >
                        {message.content}
                      </Typography>
                    </Stack>
                  </MessageContainer>
                );
              }
            )}
            {sendMessage.isLoading && (
              <MessageContainer
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                exit={{ opacity: 0, y: -10 }}
                sx={{
                  px: 2,
                  gap: 1,
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <CircularProgress size={24} color="secondary" />
                <Typography variant="body2">Working on response...</Typography>
              </MessageContainer>
            )}
          </LayoutGroup>
        </AnimatePresence>
      </Stack>
      <Stack>
        {!messages.length && (
          <Stack
            sx={{
              p: 2,
              position: "relative",
              justifyContent: "flex-start",
              gap: 1,
              flexDirection: "row",
              overflowX: "auto",
              ["&::-webkit-scrollbar"]: {
                display: "none",
              },
              maskImage: `linear-gradient(to right, white 80%, transparent)`,
            }}
          >
            {CopilotSuggestions.map((suggestion) => (
              <Chip
                sx={{
                  height: 40,
                  background: (t) => `${t.palette.text.primary}11`,
                  borderBottomRightRadius: 0,
                }}
                key={suggestion.label}
                variant="outlined"
                label={suggestion.label}
                onClick={() => {
                  addMessage({
                    author: "user",
                    content: suggestion.message,
                  });
                  ga4.event({
                    category: "copilot",
                    action: "Copilot suggestion clicked",
                  });
                  handlePrompt(suggestion.prompt);
                }}
              />
            ))}
          </Stack>
        )}
        <Divider />
        <form onSubmit={onSubmit}>
          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            sx={{ px: 2, py: 1 }}
            gap={1}
          >
            <InputBase
              minRows={1}
              maxRows={5}
              autoFocus
              autoComplete="off"
              placeholder="Write your question or task..."
              {...register("message", { required: "true" })}
              fullWidth
            />
            <IconButton
              disabled={sendMessage.isLoading}
              type="submit"
              color="secondary"
            >
              <Sparkle />
            </IconButton>
            {!!messages.length && (
              <>
                <Divider orientation="vertical" flexItem />
                <Button
                  sx={{ width: 110, opacity: 0.7 }}
                  color="inherit"
                  onClick={() => restart()}
                  endIcon={<Restore />}
                >
                  Clear
                </Button>
              </>
            )}
          </Stack>
        </form>
      </Stack>
    </Stack>
  );
};

const MessageContainer = styled(motion(Stack))``;
const PulsingSpark = styled(Sparkle)<{ isDone?: boolean }>`
  animation: ${({ isDone }) =>
    `pulse 1s ${!isDone ? "infinite" : ""} alternate ease`};
  @keyframes pulse {
    from {
      transform: scale(0.9);
      opacity: 0.6;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }
`;
