import {
  Button,
  Divider,
  IconButton,
  styled,
  Table,
  TableCell,
  TableRow,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@mui/material";
import { Check, Close, Label, MoreHorizontalThin } from "@promaton/icons";
import { FileType, useObjects } from "@promaton/scan-viewer";
import { merge } from "lodash-es";
import { FC, lazy, memo, ReactNode, Suspense, useState } from "react";
import { useAsync } from "react-use";
import { z } from "zod";
import { shallow } from "zustand/shallow";

import { flipDicom } from "../helpers/flipDicom";

const JSONPreview = lazy(() => import("./JSONPreview"));

const metadataObject = z
  .object({
    warnings: z.array(z.string()).optional(),
    task_id: z.string().optional(),
    orientation: z.array(z.any()).optional(),
  })
  .passthrough();

export const Metadata = memo(() => {
  const [jsonPreview, setJsonPreview] = useState<null | {
    title: string;
    text: string;
  }>(null);

  const metadataFiles = useObjects(
    (s) =>
      Object.entries(s.objects)
        .filter(([_, value]) => value?.objectType === FileType.JSON)
        .map(([id]) => id),
    shallow
  );

  const metadata = useAsync(async () => {
    const objects = useObjects.getState().objects;
    const json = await Promise.all(
      metadataFiles.map(async (id) => {
        const file = objects[id];
        if (!file) return [id, null] as const;
        const url = Array.isArray(file.url) ? file.url[0] : file.url;
        if (!url) return [id, null] as const;
        const json = await fetch(url).then((res) => res.json());
        const jsonString = JSON.stringify(json, null, 2);
        return [id, jsonString] as const;
      })
    );
    return json;
  }, [metadataFiles]);

  const mergedMetadataLoader = useAsync(async () => {
    if (!metadata.value) return;
    const res = await metadataObject.safeParseAsync(
      merge({}, ...metadata.value.map(([_, value]) => value))
    );
    return res.success && res.data;
  }, [metadata.value]);

  const mergedMetadata = mergedMetadataLoader.value;

  const objectCount = useObjects((s) => Object.keys(s.objects).length);

  const scanMeta = useObjects((s) => s.scanMetadata);

  return (
    <>
      <Typography variant="subtitle2" mt={1}>
        Metadata
      </Typography>
      <Table size="small" sx={{ overflow: "hidden" }}>
        <MetadataItem label="Objects" value={objectCount} />
        {mergedMetadata && (
          <>
            {mergedMetadata.task_id && (
              <MetadataItem label="Task" value={mergedMetadata.task_id} />
            )}
            {mergedMetadata.orientation && (
              <MetadataItem
                label="Orientation"
                value={!!mergedMetadata.orientation}
                detail={mergedMetadata.orientation
                  .map((i) => i.map((num: number) => num.toFixed(2)).join(", "))
                  .join("\n")
                  .toString()}
              />
            )}
            {mergedMetadata.warnings ? (
              <MetadataItem
                label="Warnings"
                value={mergedMetadata.warnings.length}
                isWarning={!!mergedMetadata.warnings.length}
                detail={
                  mergedMetadata.warnings.length
                    ? mergedMetadata.warnings.join("\n\n")
                    : undefined
                }
              />
            ) : null}
          </>
        )}
        {scanMeta && (
          <>
            {scanMeta.type && (
              <MetadataItem label="Scan type" value={scanMeta.type} />
            )}
            {scanMeta.size && (
              <MetadataItem
                label="Scan size (mm)"
                value={scanMeta.size.map((i) => i.toFixed(2)).join(" x ")}
              />
            )}
            {scanMeta.size && scanMeta.spacing && (
              <MetadataItem
                label="Pixel size"
                value={scanMeta.size
                  .map((n, i) => Math.round(n / (scanMeta.spacing![i] ?? 1)))
                  .join(" x ")}
              />
            )}
            {scanMeta.spacing && (
              <MetadataItem
                label="Scan spacing (mm)"
                value={scanMeta.spacing.map((i) => i.toFixed(2)).join(", ")}
              />
            )}
            {scanMeta.origin && (
              <MetadataItem
                label="Scan origin"
                value={scanMeta.origin.map((i) => i.toFixed(2)).join(", ")}
              />
            )}
            {scanMeta.flippedX !== undefined && (
              <MetadataItem
                label="Flipped X"
                value={scanMeta.flippedX}
                valueAction={
                  <>
                    <Button variant="text" onClick={() => flipDicom("x")}>
                      Flip
                    </Button>
                    <Divider orientation="vertical" flexItem sx={{ mr: 2 }} />
                  </>
                }
              />
            )}
            {scanMeta.flippedY !== undefined && (
              <MetadataItem
                label="Flipped Y"
                value={scanMeta.flippedY}
                valueAction={
                  <>
                    <Button variant="text" onClick={() => flipDicom("y")}>
                      Flip
                    </Button>
                    <Divider orientation="vertical" flexItem sx={{ mr: 2 }} />
                  </>
                }
              />
            )}
            {scanMeta.windowCenter || scanMeta.windowCenter === 0 ? (
              <MetadataItem
                label="Window center"
                value={scanMeta.windowCenter}
              />
            ) : undefined}
            {scanMeta.windowWidth ? (
              <MetadataItem label="Window width" value={scanMeta.windowWidth} />
            ) : null}
          </>
        )}
      </Table>
      {metadata.value?.length ? (
        <>
          <Typography variant="subtitle2" mt={1}>
            JSON files
          </Typography>

          <Table size="small" sx={{ overflow: "hidden" }}>
            {metadata.value.map(([title, text]) => {
              if (!text) return null;
              return (
                <MetadataItem
                  onClick={() => setJsonPreview({ title, text })}
                  icon={<Label />}
                  label={`${title.split("/").at(-1)}.json`}
                  key={title}
                />
              );
            })}
          </Table>
        </>
      ) : undefined}
      <Suspense>
        {jsonPreview && (
          <JSONPreview
            data={jsonPreview}
            open
            onClose={() => {
              setJsonPreview(null);
            }}
          />
        )}
      </Suspense>
    </>
  );
});

const MetadataItem: FC<{
  icon?: ReactNode;
  label: string;
  value?: string | boolean | number;
  isWarning?: boolean;
  detail?: string;
  onClick?: () => void;
  valueAction?: ReactNode;
}> = ({ label, value, detail, isWarning, icon, onClick, valueAction }) => {
  return (
    <TableRow
      hover={!!onClick}
      onClick={onClick}
      sx={{
        cursor: onClick ? "pointer" : undefined,
        height: (t) => t.spacing(6),
        ["&  td"]: {
          borderBottomColor: "transparent",
        },
        ["&:not(:last-child) td"]: {
          borderBottomColor: (t) => t.palette.divider,
        },
      }}
    >
      {icon && (
        <TableCell
          sx={{
            width: 30,
            whiteSpace: "nowrap",
            pt: 1.25,
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          {icon}
        </TableCell>
      )}
      <TableCell
        sx={{
          whiteSpace: "nowrap",
          maxWidth: "150px",
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        <Typography
          fontSize={"small"}
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          {label}
        </Typography>
      </TableCell>
      <TableCell
        align="right"
        sx={{
          paddingLeft: 0,
          paddingRight: 0,
          overflow: "hidden",
          maxWidth: "150px",
        }}
      >
        <Typography
          sx={{
            maxWidth: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
          fontSize={"small"}
          color={isWarning ? "error" : "inherit"}
          fontWeight={isWarning ? "bold" : "inherit"}
          title={value?.toString()}
        >
          {valueAction}
          {typeof value === "boolean" ? (
            value ? (
              <Check fontSize="small" sx={{ marginTop: "0.25em" }} />
            ) : (
              <Close fontSize="small" sx={{ marginTop: "0.25em" }} />
            )
          ) : (
            value
          )}
        </Typography>
      </TableCell>
      <TableCell
        align="right"
        sx={{
          paddingLeft: 0,
          paddingRight: 0,
          width: 24,
        }}
      >
        {detail && (
          <InfoTooltip title={detail}>
            <IconButton color="inherit" size="small">
              <MoreHorizontalThin sx={{ verticalAlign: "center" }} />
            </IconButton>
          </InfoTooltip>
        )}
      </TableCell>
    </TableRow>
  );
};

const InfoTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    whiteSpace: "pre-wrap",
    padding: theme.spacing(2),
  },
}));
